import { AuthErrorType } from '../../../provider/AuthProvider';

import ErrorDialog from '../../common/dialog/error-dialog/ErrorDialog';
import InfoDialog from '../../common/dialog/info-dialog/InfoDialog';
import WarningDialog from '../../common/dialog/warning-dialog/WarningDialog';

const AuthDialog = ({ isOpen, authError, onClose, onClick }) => {
  const Dialog = ({ isOpen, message, onClose, onClick }) => {
    switch (authError?.type) {
      case AuthErrorType.INFO:
        return (
          <InfoDialog
            isOpen={isOpen}
            title=""
            message={message}
            onClose={onClose}
            onClick={onClick}
          />
        );
      case AuthErrorType.WARNING:
        return (
          <WarningDialog
            isOpen={isOpen}
            title=""
            message={message}
            onClose={onClose}
            onClick={onClick}
          />
        );
      default:
        return (
          <ErrorDialog
            isOpen={isOpen}
            title="Authentication Error"
            message={message}
            onClose={onClose}
            onClick={onClick}
          />
        );
    }
  };

  return (
    <>
      {authError && (
        <Dialog isOpen={isOpen} message={authError?.message} onClose={onClose} onClick={onClick} />
      )}
    </>
  );
};

export default AuthDialog;
