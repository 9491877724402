import React from 'react';

import { useAppNavigation } from '../../hooks/useAppNavigation';
import { useAuth } from '../../hooks/useAuth';
import { useMobile } from '../../hooks/useMobile';
import { usePurchase } from '../../hooks/usePurchase';

import './header.scss';

import CartTotal from '../cart-total/CartTotal';
import Search from '../common/search/Search';

const Header = () => {
  const { user } = useAuth();
  const { cart } = usePurchase();
  const { showCartTotal } = useAppNavigation();
  const isMobile = useMobile();

  return (
    <div className="header">
      <div>
        <div className="header__welcome">
          <span>Hi {user?.displayName}</span>
          <span>Welcome Back!</span>
        </div>
        {showCartTotal && <CartTotal total={cart} />}
      </div>
      <div>{isMobile && <Search />}</div>
    </div>
  );
};

export default Header;
