import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MenuContext } from '../context/MenuContext';

import { useDebounce } from '../hooks/useDebounce';

import { getMenuCategoriesRequest } from '../api/category/category.action';
import { getMenusRequest } from '../api/menu/menu.action';
import { getPromotionsRequest } from '../api/promotion/promotion.action';

export const LAYOUT_GRID = 'grid';
export const LAYOUT_LIST = 'list';

export const MenuProvider = ({ children }) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 100);
  const [menuLayout, setMenuLayout] = useState(LAYOUT_GRID);

  const dispatch = useDispatch();
  const { menus, loading: menusLoading } = useSelector((state) => state.menu);
  const { categories, loading: categoriesLoading } = useSelector((state) => state.categories);
  const { promotions, loading: promotionsLoading } = useSelector((state) => state.promotions);

  const [selectedCategory, setSelectedCategory] = useState(
    categories.length > 0 ? categories.find((category) => !category.filterEnabled)?.id : null
  );

  const getUnfilteredCategory = (categories) =>
    categories.find((category) => !category.filterEnabled)?.id;

  const handleSearch = (e) => {
    setQuery(e?.value);
  };

  useEffect(() => {
    dispatch(getMenusRequest());
    dispatch(
      getMenuCategoriesRequest((categories) => {
        setSelectedCategory(getUnfilteredCategory(categories));
      })
    );
    dispatch(getPromotionsRequest());
  }, [dispatch]);

  const memoizedMenus = useMemo(() => {
    let searchedMenus = [...menus];

    if (debouncedQuery) {
      searchedMenus = menus.reduce((list, menu) => {
        const filteredItems = menu.menuItems.filter(
          (item) =>
            item.name.toLowerCase().includes(debouncedQuery.toLowerCase()) ||
            item.description.toLowerCase().includes(debouncedQuery.toLowerCase())
        );

        if (filteredItems.length > 0) {
          list.push({ ...menu, menuItems: filteredItems });
        }

        return list;
      }, []);
    }

    if (selectedCategory === getUnfilteredCategory(categories)) {
      return searchedMenus;
    }

    return searchedMenus.reduce((list, menu) => {
      const filteredItems = menu.menuItems.filter((item) => {
        return (
          item.categories.filter((category) => {
            return category.id === selectedCategory;
          }).length > 0
        );
      });

      if (filteredItems.length > 0) {
        list.push({ ...menu, menuItems: filteredItems });
      }

      return list;
    }, []);
  }, [selectedCategory, debouncedQuery, menus, categories]);

  return (
    <MenuContext.Provider
      value={{
        promotions,
        categories,
        menus: memoizedMenus,
        selectedCategory,
        setSelectedCategory,
        handleSearch,
        query,
        menuLayout,
        setMenuLayout,
        menusLoading,
        categoriesLoading,
        promotionsLoading
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
