import { useMenus } from '../../hooks/useMenus';

import './sidebar.scss';

import { Promotions } from '../promotion/Promotions';

export const Sidebar = () => {
  const { promotions } = useMenus();

  return (
    <div className="sidebar">
      <Promotions promotions={promotions} />
    </div>
  );
};
