import {
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signOut
} from 'firebase/auth';
import { useEffect, useState } from 'react';

import { AuthContext } from '../context/AuthContext';

import { useFirebase } from '../hooks/useFirebase';

export const AuthStatus = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INVALID_USERNAME: 'INVALID_USERNAME',
  INCORRECT_PASSWORD: 'INCORRECT_PASSWORD',
  INVALID_CREDENTIAL: 'INVALID_CREDENTIAL',
  USERNAME_IN_USE: 'USERNAME_IN_USE'
};

export const AuthErrorType = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO'
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [authStatus, setAuthStatus] = useState(undefined);
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const { auth } = useFirebase();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
      setPageLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  const clearAuthError = () => {
    setAuthStatus(null);
  };

  const processError = (error) => {
    setFormData((prevData) => ({ ...prevData, password: '' }));

    //eslint-disable-next-line
    console.log(error);

    if (
      error.code === 'auth/user-not-found' ||
      error.code === 'auth/invalid-email' ||
      error.code === 'auth/wrong-password' ||
      error.code === 'auth/invalid-credential'
    ) {
      setAuthStatus({
        type: AuthErrorType.INFO,
        message: 'Invalid username/password! Please try again.',
        status: AuthStatus.INVALID_CREDENTIAL
      });
    } else if (error.code === 'auth/email-already-in-use') {
      setAuthStatus({
        type: AuthErrorType.WARNING,
        message: 'Account for email already exists',
        status: AuthStatus.USERNAME_IN_USE
      });
    } else {
      setAuthStatus({
        message: 'There was an error trying to authenticate your account.',
        status: AuthStatus.ERROR
      });
    }
  };

  const signInWithEmail = async (email, password, onComplete) => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
      onComplete(AuthStatus.SUCCESS);
      setFormData({});
    } catch (error) {
      processError(error, onComplete);
    } finally {
      setLoading(false);
    }
  };

  const registerWithEmail = async (email, password, onComplete) => {
    try {
      setLoading(true);
      await createUserWithEmailAndPassword(auth, email, password);
      clear();
      onComplete(AuthStatus.SUCCESS);
    } catch (error) {
      processError(error, onComplete);
    } finally {
      setLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      setLoading(true);
      await signInWithPopup(auth, provider);
      clear();
    } catch (error) {
      processError(error);
    } finally {
      setLoading(false);
    }
  };

  const signOutUser = () => {
    clear();
    signOut(auth);
  };

  const clear = () => {
    clearFormData();
    clearAuthError();
    setPageIndex(0);
  };

  const clearFormData = () => {
    setFormData(() => {});
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        user,
        pageIndex,
        setPageIndex,
        authError: authStatus,
        clearAuthError,
        pageLoading,
        loading,
        formData,
        setFormData,
        signInWithGoogle,
        signInWithEmail,
        signOut: signOutUser,
        registerWithEmail,
        clearFormData
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
