import React from 'react';

import { useMenus } from '../../../hooks/useMenus';
import { useMobile } from '../../../hooks/useMobile';
import { useTheme } from '../../../hooks/useTheme';

import { LAYOUT_GRID, LAYOUT_LIST } from '../../../provider/MenuProvider';

import { ReactComponent as LayoutGrid } from '../../../icons/layout-grid.svg';
import { ReactComponent as LayoutList } from '../../../icons/layout-list.svg';

import './menu.scss';

import { Categories } from '../../category/Categories';
import Button from '../../common/button/Button';
import Search from '../../common/search/Search';
import Hero from '../../hero/Hero';
import { MenuGrid } from '../../menu/grid/MenuGrid';
import { MenuList } from '../../menu/list/MenuList';
import Navigation from '../../navigation/Navigation';
import { Promotions } from '../../promotion/Promotions';
import { Sidebar } from '../../sidebar/Sidebar';

const Menu = () => {
  const isMobile = useMobile();
  const { promotions, categories, menus, query, menuLayout, setMenuLayout, menusLoading } =
    useMenus();
  const { themeConfig } = useTheme();
  const backgroundColor = themeConfig?.secondary?.background?.DEFAULT;
  const textColor = themeConfig?.primary?.text?.DEFAULT;

  return (
    <Navigation>
      <div className="page-menu">
        <div>
          <div>
            {!isMobile && (
              <div>
                {menuLayout === LAYOUT_GRID && (
                  <LayoutList
                    key={LAYOUT_GRID}
                    onClick={() => setMenuLayout(LAYOUT_LIST)}
                    style={{ background: backgroundColor, color: textColor }}
                  />
                )}
                {menuLayout === LAYOUT_LIST && (
                  <LayoutGrid
                    key={LAYOUT_LIST}
                    onClick={() => setMenuLayout(LAYOUT_GRID)}
                    style={{ background: backgroundColor, color: textColor }}
                  />
                )}
                <Search />
                <Button className="page-menu__action-button">CREATE MEAL</Button>
              </div>
            )}
            {!query && <Hero />}
            {!query && <Promotions promotions={promotions} />}
            <Categories categories={categories} />
            <div className="page-menu__menus">
              {!menusLoading &&
                menus.map((item, index) =>
                  menuLayout === LAYOUT_LIST ? (
                    <MenuList
                      key={`${item.title} ${index}`}
                      title={item.title}
                      items={item.menuItems}
                    />
                  ) : (
                    <MenuGrid
                      key={`${item.title} ${index}`}
                      title={item.title}
                      items={item.menuItems}
                      loading={menusLoading}
                    />
                  )
                )}
              {menusLoading && (
                <MenusShimmer rows={2} menuLayout={menuLayout} loading={menusLoading} />
              )}
            </div>
          </div>
        </div>
        {!isMobile && <Sidebar />}
      </div>
    </Navigation>
  );
};

const MenusShimmer = ({ rows, menuLayout, loading }) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
    {Array.from(Array(rows).keys()).map((key) =>
      menuLayout === LAYOUT_LIST ? (
        <MenuList key={key} loading={loading} />
      ) : (
        <MenuGrid key={key} loading={loading} />
      )
    )}
  </div>
);

export default Menu;
